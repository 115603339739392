@font-face {
  font-family: 'RelativeMono';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/relative/relative-book-pro.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/relative/relative-book-pro.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/relative/relative-book-pro.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/relative/relative-book-pro.woff') format('woff'), /* Modern Browsers */
       url('./fonts/relative/relative-book-pro.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'RelativeMono';
  font-style: bold;
  font-weight: 500;
  src: url('./fonts/relative/relative-bold-pro.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/relative/relative-bold-pro.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/relative/relative-bold-pro.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/relative/relative-bold-pro.woff') format('woff'), /* Modern Browsers */
       url('./fonts/relative/relative-bold-pro.ttf') format('truetype'); /* Safari, Android, iOS */
}

body {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  font-family: RelativeMono, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.recharts-wrapper {
  font-size: 0.8rem;
  font-family: Monaco, Consolas, monospace;
}

.recharts-legend-item-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.App {
  color: #000;
  background: #f6f9ff;
  line-height: 1.15;
  padding: 1rem;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  font-size: var(--font-base);
  padding-bottom: 2rem;

}

.App-footer {
  left: 0;
  right: 0;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 0rem;
  background-color: #16182e;;
}

.App.dark {
  background: #101124;
  color: #FFF;
  line-height: 1.15;
  box-sizing: border-box;
  min-height: 100vh;
}

h1 {
  display: flex;
  align-items: center;
  user-select: none;
}

h1 span:first-child {
  margin-right: 5px;
  margin-bottom: -10px;
}


h3, h2 {
  color: #444;
  margin: 1rem 0 1.5rem;
  user-select: none;
}

.App.dark h3, .App.dark h2 {
  color: white;
}

h3 {
  margin: 1rem 0;
  font-weight: normal;
  font-size: 1.2rem;
  user-select: none;
}

h2 {
  font-size: 1.6rem;
  font-weight: bold
}

label {
  cursor: pointer;
}

input + label,
label + input {
  margin-left: 0.5rem;
}

.relative-container {
  position: relative;
}

.top-right-description {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1rem;
  margin-right: 1rem;
}

.header-and-form {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.form-container {
  display: flex;
  gap: 10px;
  margin-left: 20px;
}

.search-container {
  display: flex;
  align-items: left;
  gap: 15px;
  margin-top: 0px;
}

.search-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #666;
  outline: none;
  padding: 5px 0;
  width: 80%;
  color: black;
  font-size: 1.5em;
}

.search-input:focus {
  border-bottom: 2px solid black;
}

.submit-button {
  font-size: 1.04em;
  padding: 5px 20px;
  width: 20%;
}

.chart-subtitle, .stats {
  margin: 1.5rem 0 1rem;
}

.chart-description {
  margin: 1rem 0 0;
  max-width: 600px;
}

.App.dark .chart-description {
  color: #A0A3C4;
}

.chart-description p:first-child {
  margin-top: 0;
}

.chart-description p:last-child {
  margin-bottom: 0;
}

.chart-description ul {
  margin: 0;
  padding-left: 20px;
}

.chart-description li {
  margin-top: 5px;
}

.chart-grid {
  color: #16182E;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}


.chart-grid-subtitle {
  grid-column-start: 1;
  grid-column-end: 9;
  margin-bottom: 0;
  margin-top: 2rem;
}

.total-stat-value {
  font-size: 1.5rem;
  overflow: hidden;
  white-space: pre-wrap;
}

.total-stat-delta {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: block;
  text-align: center;
}

.total-stat-delta.plus {
  color: #46E3AE;
}
.App.light .total-stat-delta.plus {
  color: rgb(34 199 97);
}
.total-stat-delta.minus {
  color: #727171;
}

.App.dark .chart-cell {
  background: #16182E;
  border: none;
  color: white;
}

.App.dark .chart-cell.stats {
  border: 1px solid #FFFFFF14;
}

.App.dark .chart-cell.search.tier {
  background: #16182E;
  border: none;
  color: white;
}

.App.dark .search-input{
  color: white;
  border-bottom: 1px solid #666; /* Add a bottom border only */
}

.App.dark .search-input:focus{
  border-bottom: 2px solid white; /* Change the border color on focus */
}

.volume-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.volume-v1,
.volume-v2,
.volume-total {
  margin-right: 10px;
}

.chart-card {
  background: #f6f9ff; /* Match this with your app's background color */
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  color: #16182E; /* Text color */
  grid-column-start: span 5;
  user-select: none;
}

.chart-title {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

/* If you have a dark theme variant */
.App.dark .chart-card {
  background: #101124; /* Dark background */
  color: #FFF; /* Light text */
}


.referral-table {
  padding: 1rem; /* This matches the padding of other chart cells */
  background: white;
  border-radius: 4px;
  border: 1px solid #e6e6f7;
  position: relative;
  text-align: left;
  overflow-x: auto; /* Enable horizontal scrolling */
  grid-column-start: span 6;
  min-height: 420px;
}

.referral-table-placeholder {
  opacity: 0.1; /* Adjust the value as needed, lower means more transparent */
}

.referral-table table {
  width: 100%;
}

.referral-table th, .referral-table td {
  padding: 0.3rem; /* Adjust padding as needed */
  border-bottom: 1px solid rgba(230, 230, 247, 0.1); /* Adjusted for opacity */
  text-align: left;
}

.referral-table th {
  background-color: #f6f9ff; /* Background color for headers */
  color: #16182E; /* Text color for headers */
}

.referral-table tr:last-child td {
  border-bottom: none; /* Remove border from the last row */
}

/* CSS */
.bar-cell {
  text-align: center;
  padding: 0px;
}

.bar {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  height: 10px;
  width: 100%; /* Extend the bar to the cell width */
}

.bar-label {
  text-align: center;
  font-size: 10px; /* Adjust the font size as needed */
  background-color: transparent; /* Set the background color to transparent */
  padding: 0; /* Remove any padding */
  color: white; /* Set the text color as desired */
}

.bar-segment {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 10px;
}

.bar-padding {
  width: 4px; /* Reduce the width to 1px */
}

.total {
  margin-bottom: 3px;
  text-align: left;
}

.App.dark .referral-table {
  background: #16182E;
  color: white;
  border: 1px solid #FFFFFF14;
}



.chart-cell {
  margin: 0;
  border-radius: 4px;
  border: 1px solid #e6e6f7;
  background: white;
  position: relative;
  padding: 1rem;
  grid-column-start: span 3;
}

.chart-cell.search {
  margin: 0;
  border-radius: 4px;
  border: 1px solid #e6e6f7;
  background: white;
  position: relative;
  padding: 1rem;
  grid-column-start: span 6;
}

.chart-cell.search.stats {
  min-height: 60px;
  grid-column-start: span 3;
  text-align: center;
}

.chart-cell.search.tier {
  margin: 0;
  border-radius: 4px;
  border: 1px solid #e6e6f7;
  background: white;
  position: relative;
  padding: 1rem;
  grid-column-start: span 3;
}


.chart-cell.stats {
  min-height: 65px;
  grid-column-start: span 2;
  text-align: center;
}

.chart-cell h3 {
  margin-top: 0;
  letter-spacing: 0px;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

.chart-cell .csv-link {
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 0.3rem;
  opacity: 0.5;
  display: inline-flex;
  vertical-align: middle;
}
.chart-cell:hover .csv-link {
  opacity: 0.5;
}
.chart-cell .csv-link:hover {
  opacity: 1;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.chart-controls {
    display: flex;
}

.chart-control-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 8px;
  padding: 8px 7px;
  color: #666;
  font-weight: bold;
  background: #eee;
  border-radius: 4px;
  transition: background-color .1s, color .1s;
  font-size: 14px;
}

.chart-control-checkbox:hover {
  color: #000;
}

.chart-control-checkbox.active {
  background-color: #609ade;
  color: #fff;
}

.App.dark .chart-control-checkbox {
  background: #333;
  color: #aaa;
}

.App.dark .chart-control-checkbox:hover {
  color: #fff;
}

.App.dark .chart-control-checkbox.active {
  background: #609ade;
  color: #fff;
}

.form input[type="date"],
.form input[type="text"] {
  border: 1px solid #aaa;
  border-radius: 3px;
  appearance: none !important;
  box-sizing: border-box;
  padding: 3px;
}

.form button {
  border: none;
  appearance: none !important;
  color: #000;
  border: 1px solid #84aefb;
  box-sizing: border-box;
  background: #9fc1ff;
  cursor: pointer;
  margin-left: 10px;
  height: 28px;
  border-radius: 3px;
  padding: 3px 7px;
}
.form button:hover {
  opacity: 0.9;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: loader 1s infinite;
  opacity: 0.2;
}

.chart-cell .loader {
  position: absolute;
  margin-left: -1.5em;
  margin-top: -0.65rem;
  font-size: 0.7rem;
  top: 50%;
  left: 50%;
  z-index: 2;
}


.warning, .warning a {
  color: #e46b00;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -1rem 0;
  height: 55px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid #A0A3C4;
  background-color: white;
}

.nav-center {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.App.dark .nav {
  background: #101124;
  border-bottom: 1px solid #FFFFFF29;
}

.nav-logo {
  width: 87px;
  vertical-align: middle;
  margin: 0px 0.5rem 0 0;
  display: inline-flex;
}

.nav-logo img {
  width: 87px;
}

a, a:active, a:visited {
  color: #16182E;
}

.nav-link {
  letter-spacing: 0.47px;
  color: #A0A3C4;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.125rem;
  letter-spacing: 0.47px;
}

.nav-link:active, .nav-link:visited {
  color: #A0A3C4;
}

.nav-link.active {
  color: #444;
}

.nav-link img.profile-pic{
  margin-right: -10px;
}

.non-selectable {
  user-select: none;
}

.update-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: flex-end; /* Aligns container content to the right */
  margin-top: 10px;
}

/*interval selector*/
.interval-controls {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 5px;
}

.toggle-button {
  background-color: transparent;
  border: none;
  padding: 5px 15px;
  margin: 0 0px;
  cursor: pointer;
  font-weight: bold;
  color: #6c757d; /* Match StatCell text color */
  outline: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.toggle-button.active {
  background-color: #101124; /* Highlight color for active button */
  color: white;
}

.toggle-button:hover {
  background-color: #6c757d; /* Hover state color */
  color: white; /* Match StatCell text color */
}

/**/



.profile-pic{
  border-radius: 50%;
  width: 30px;
  object-fit: cover; /* Ensures the image covers the area without stretching */
}


.App.dark a, .App.dark a:active, .App.dark a:visited {
  color: #FFFFFF;
}

.App.dark .nav-link {
  color: #A0A3C4;
}

.App.dark .nav-link:active, .App.dark .nav-link:visited {
  color: #A0A3C4;
}

.App.dark .nav-link.active {
  color: white;
}

.App.dark .nav-link:hover {
  color: white;
}

.nav-link:hover {
  color: #444;
}

.nav-right {
  display: flex;
  align-items: center;
}

.modeselect {
  width: 30px;
  height: 26px;
  background: #16182E 0% 0% no-repeat padding-box;
  border: 1px solid #FFFFFF0F;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.App.dark .modeselect {
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.modeselect svg {
  fill: #A0A3C4;
}

.App.dark .modeselect svg {
  fill: #16182E;
}

.page-title {
  font-size: 1.8125rem;
  line-height: 2.3125rem;
}

.recharts-cartesian-axis-tick-value {
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-axis-tick-value {
  fill: #A0A3C4;
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-grid-horizontal line, .App.dark .recharts-cartesian-grid-vertical line {
  stroke: #282a3e;
}

.recharts-tooltip-wrapper .recharts-default-tooltip {
  padding: 5px 12px!important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li {
  padding: 0px!important;
  font-size: 12px!important;
  line-height: 15px!important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li + li {
  margin-top: 1px!important;
}

.App.dark .recharts-tooltip-wrapper .recharts-default-tooltip {
  background-color: #353651!important;
  box-shadow: 0px 3px 6px #0000005C;
  border: 1px solid #484B6E!important;
  border-radius: 4px;
}

.custom-tooltip {
  padding: 12px 12px;
  background-color: #eee; /* Adjust based on theme */
  box-shadow: 0px 3px 6px #0000005C;
  border: 1px solid #484B6E;
  border-radius: 4px;
  line-height: 5px;
}

.custom-tooltip p {
  padding: 0;
  margin-top: 0px;
  font-size: 12px; /* Set the desired font size */
}

.custom-tooltip p:first-child {
  margin-top: 0;
}

.custom-tooltip p:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}



.App.dark .custom-tooltip {
  padding: 12px 12px;
  background-color: #353651; /* Adjust based on theme */
  box-shadow: 0px 3px 6px #0000005C;
  border: 1px solid #484B6E;
  border-radius: 4px;
  line-height: 5px;
}


.App-header-drawer {
  background: #101124 0% 0% no-repeat padding-box;
  box-shadow: 8px 3px 6px #00000029;
  opacity: 1;
  backdrop-filter: blur(27px);
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 304px;
}

.App-header-drawer .App-header-link-container a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #A0A3C4;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
}

.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: #303FD024;
  color: white;
}

.App-header-drawer .App-header-menu-icon {
  color: white;
  font-size: 1.3rem;
}

.App-header-drawer .App-header-link-main {
  display: flex;
  align-items: center;
}

.App-header-links-header {
  height: 62px;
  display: flex;
  align-items: center;
  z-index: 3;
  padding-right: 1rem;
}

.App-header-menu-icon {
  color: black;
  font-size: 1.3rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 9px 10px;
}

.App.dark .App-header-menu-icon {
  color: white;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

@media all and (max-width: 1000px) {
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .chart-cell {
    grid-column-start: span 2;
    grid-column-end: auto;
  }
  .chart-cell.stats {
    grid-column-start: span 1;
  }

}

.content {
  margin-top: 1rem;
}

.App-header-menu-icon-block {
  display: none;
}

.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.date-range-selector-wrapper > div {
  display: flex;
  justify-content: flex-end;
}

.react-dropdown-select-dropdown {
  position: absolute;
  top: 43px;
  right: 0px;
  left: unset!important;
  border: 1px solid #ccc;
  width: 348px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  overflow: auto;
  z-index: 9;
  max-height: 475px!important;
  width: 270px!important;
}

.App.dark .react-dropdown-select-dropdown {
  background-color: #101124!important;
  border: 1px solid #FFFFFF14!important;
}

.react-dropdown-select {
  border: 1px solid #e6e6f7!important;
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 2px;
  padding: 2px 5px;
  flex-direction: row;
  direction: ltr;
  align-items: center;
  cursor: pointer;
  min-height: 36px;
  pointer-events: all;
  max-width: fit-content;
  min-width: 215px!important;
  background-color: white;
  padding-left: 1rem!important;
  padding-right: 1rem!important;
}

.react-dropdown-select-dropdown-handle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.App.dark .react-dropdown-select {
  border: 1px solid #FFFFFF14!important;
  background-color: #16182E;
  width: 200px;
}

.date-range-items {
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
}

.date-range-item {
  display: flex;
  margin: 0.5rem;
  padding: 0.5rem;
  align-items: baseline;
}

.date-range-item__label {
  color: #A0A3C4;
}

.date-range-item.selected .date-range-item__label,
.date-range-item:hover .date-range-item__label {
  color: #444;
}

.App.dark .date-range-item__label {
  color: #A0A3C4;
}

.App.dark .date-range-item.selected .date-range-item__label,
.App.dark .date-range-item:hover .date-range-item__label {
  color: white;
}

.date-range-custom {
  color: #000;
  margin: 0.5rem;
  text-align: center;
}

.react-dropdown-select-content {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.chart-cell.mobile {
  display: none;
}

.date-range-selector-wrapper .rdrDay {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
}

.date-range-selector-wrapper .rdrMonth {
  width: 240px;
}

.date-range-selector-wrapper .rdrMonthAndYearPickers select {
  padding: 10px;
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrMonthAndYearPickers select {
  color: white;
  background: #16182E;
}

.date-range-selector-wrapper .rdrNextPrevButton {
  padding: 0;
}

.date-range-selector-wrapper .rdrCalendarWrapper {
  background-color: #EEE;
}

.App.dark .date-range-selector-wrapper .rdrCalendarWrapper {
  background-color: #16182E;
}

.date-range-selector-wrapper .rdrWeekDay {
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrWeekDay {
  color: white;
}

.date-range-selector-wrapper .rdrDayNumber span {
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrDayNumber span {
  color: white;
}

.date-range-selector-wrapper .rdrDayToday .rdrDayNumber span:after {
  bottom: 1px;
}

.rdrDayPassive {
  opacity: 0.5;
}

.page-title-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page-title-section h1 {
  margin: 0;
}

h1{
  padding-top: -10px;
}

.page-description {
  margin: 0;
}

.referrals-dashboard{
  user-select: none;
}

@media (max-width: 1033px) {
  .App-header-link-main img {
    height: 1.3125rem;
  }
  .referrals-dashboard{
    display: none;
  }
  .docs-link{
    display: none;
  }
  .kyzo-text {
    display: none;
  }
}

@media all and (max-width: 600px) {
  .page-title-section {
    display: flex;
    justify-content: unset;
    align-items: unset;
    flex-direction: column;
  }
  .submit-button {
    width: 30%; /* Increase the width */
    padding: 10px 20px; /* Adjust padding if needed */
    font-size: 1.1em; /* Slightly increase font size for better readability */
  }

  .block-link{
    display: none;
  }

  .relative-container{
    display: none;
  }
  .page-title-section .form {
    margin-top: 1rem;
  }

  .chart-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .chart-cell {
    grid-column-start: unset!important;
    grid-column-end: unset!important;
  }

  .chart-cell.mobile {
    display: block;
  }

  .table-placeholder, .bubble-chart-placeholder {
    position: relative;
  }

  .top-affiliates-container {
    display: flex;
    align-items: left;
  }

  .top-affiliates-container .loader {
    margin-left: 20px; /* Adjust for appropriate spacing */
  }

  .spinner-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .referral-table {
    grid-column-start: unset!important;
    grid-column-end: unset!important;
  }

  .App-header-menu-icon-block {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }

  .nav-left {
    display: flex;
    align-items: center;
  }

  .nav-logo {
    display: flex;
  }

  .nav-left .nav-link {
    display: none;
  }

  .react-dropdown-select {
    max-width: 100%;
  }
}

@media (max-width: 450px) {
  .App-header-menu-icon {
    margin: 4px 5px!important;
  }
  .kyzo-text {
    display: none;
  }
}
